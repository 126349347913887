import {
  Box,
  Center,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spinner,
  Text,
  useToast,
} from '@chakra-ui/react';

import Avatar from 'components/common/Avatar';
import Button from 'components/common/Button';

import { EditClient } from 'components/dashboard/clients/EditClient';
import React, { useState } from 'react';
import { FaRegArrowAltCircleDown } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useDeleteClientMutation, useGetClientsQuery } from 'services/api/client';
import { AddNewClient } from './clients/AddNewClient';

import { AddIcon } from '@chakra-ui/icons';
import { FaUserEdit, FaUserSlash } from 'react-icons/fa';
import { ClientType } from 'types';

import { store } from 'app/store';
import ActionIcon from 'components/common/ActionIcon';
import { baseApi, RtkTagTypes } from 'services/api/baseApi';
import { useUserinfoHook } from 'utils/useUserinfoHook';

enum ActionType {
  'delete' = 'delete',
  'edit' = 'edit',
}

export const ClientsLists = () => {
  const toast = useToast();
  const { data: clients, isLoading, isFetching } = useGetClientsQuery();
  const { isActive: isUserActive } = useUserinfoHook();

  const [deleteClient] = useDeleteClientMutation();
  const [selectedClient, setSelectedClient] = useState<null | ClientType>(null);
  const [action, setAction] = useState<ActionType | null>(null);

  const navigate = useNavigate();
  const limitIncrement = 4;
  const [limit, setLimit] = useState(limitIncrement);

  const setClientAction = (client: ClientType | null, action: ActionType | null) => {
    setSelectedClient(client);
    setAction(action);
  };

  const handleDelete = async (client: ClientType) => {
    setSelectedClient(null);
    setAction(null);
    try {
      await deleteClient(client.id).unwrap();
      toast({
        title: 'Client deleted successfully',
        duration: 1000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Client not deleted',
        description: 'Error occurred during deletion. Check logs!',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  return (
    <Box px={8} py={4}>
      {/* Heading Section */}
      <Flex justify="space-between" align="center" mb={4}>
        <Heading as="h2" color="purple.700">
          My Clients
        </Heading>
        <AddNewClient isDisabled={!isUserActive} />
      </Flex>

      {/* Clients Grid */}
      <SimpleGrid columns={{ base: 1, md: 2, xl: 4 }} spacing={4}>
        {clients?.slice(0, limit).map((client) => {
          const { id, name, logo, short_description } = client;
          return (
            <Flex
              key={id}
              borderWidth="1px"
              borderRadius="lg"
              overflow="hidden"
              p={4}
              direction="column"
              position="relative"
              boxShadow="sm" // Subtle shadow
              transition="all 0.2s ease" // Smooth transition for hover effect
              _hover={{
                boxShadow: 'lg', // Emphasized shadow on hover
                transform: 'translateY(-4px)', // Slight lift on hover
              }}
            >
              <Flex
                direction="column"
                cursor="pointer"
                onClick={() => {
                  navigate(`/client/hp/${id}`);
                }}
              >
                <Flex align="center" mb={2}>
                  <Avatar logo={logo} name={name} boxSize="50px" size="md" />
                  <Heading as="h3" size="md" ml={3}>
                    {name}
                  </Heading>
                </Flex>
                <Text fontSize="sm" align="left">
                  {short_description}
                </Text>
              </Flex>
              <Flex justify="space-between" mt={4} gap={1}>
                <Box gap={2} display="flex">
                  <ActionIcon
                    label="Add project"
                    colourScheme="teal"
                    icon={<AddIcon />}
                    disabled={!isUserActive}
                    onClick={() => {
                      store.dispatch(
                        baseApi.util.invalidateTags([
                          RtkTagTypes.ProjectsSummary,
                          RtkTagTypes.ProjectsTopics,
                        ]),
                      );
                      navigate(`/blog_create/${id}`);
                    }}
                  />
                  <ActionIcon
                    label={`Edit`}
                    colourScheme="blue"
                    icon={<FaUserEdit />}
                    disabled={!isUserActive}
                    onClick={() => {
                      setClientAction(client, ActionType.edit);
                    }}
                  />
                </Box>
                <ActionIcon
                  label={`Delete`}
                  icon={<FaUserSlash />}
                  colourScheme="red"
                  onClick={() => {
                    setClientAction(client, ActionType.delete);
                  }}
                  disabled={!isUserActive}
                />
              </Flex>
            </Flex>
          );
        })}
      </SimpleGrid>

      {/* Show More Button */}
      {(clients?.length ?? 0) > limit && (
        <Center>
          <Button
            mt={4}
            onClick={() => setLimit(limit + limitIncrement)}
            colorScheme="teal"
            leftIcon={<FaRegArrowAltCircleDown />}
          >
            Show More
          </Button>
        </Center>
      )}

      {/* Loading Spinner */}
      {(isLoading || isFetching) && (
        <Box mb={4}>
          <Spinner /> Loading clients ...
        </Box>
      )}
      {selectedClient && action === ActionType.delete && (
        <>
          <Modal
            isOpen
            onClose={() => {
              setSelectedClient(null);
              setAction(null);
            }}
            isCentered
          >
            <ModalOverlay backdropFilter="blur(10px)" />
            <ModalContent>
              <ModalHeader>Delete client '{selectedClient.name}'?</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Text mb="1em">
                  Proceeding with this action will permanently delete all data, and recovery will
                  not be possible.
                </Text>
                <Text fontWeight="bolder">Continue with deletion?</Text>
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="red" mr={3} onClick={() => setSelectedClient(null)}>
                  No
                </Button>
                <Button colorScheme="blue" onClick={() => handleDelete(selectedClient)}>
                  Yes
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
      )}

      {selectedClient && action === ActionType.edit && (
        <>
          <EditClient
            clientId={selectedClient.id}
            onEditClose={() => setClientAction(null, null)}
          />
        </>
      )}
    </Box>
  );
};
