import React from 'react';

import { Alert, AlertDescription, AlertIcon, AlertTitle, Link } from '@chakra-ui/react';
import { GrUpgrade } from 'react-icons/gr';
import Button from 'components/common/Button';
import { useGetUserSelfQuery } from 'services/api/user';
import { getDateTime } from 'utils/dateTime';

import { ApiErrorType } from 'types';
import { PLAN_NAME_FREE, STRIPE_BUTTON_LINK } from 'utils/consts';

const SubcribeAlert = () => {
  const userSelfQuery = useGetUserSelfQuery();

  if (!STRIPE_BUTTON_LINK) {
    return null;
  }

  const error = userSelfQuery?.error as ApiErrorType;

  if (userSelfQuery?.isError) {
    switch (error.status) {
      case 402:
        console.log('userSelfQuery error.data', error.data);
        return (
          <Alert status="warning" borderRadius="lg" marginBottom={4}>
            <AlertIcon />
            <AlertTitle>Your subscription has expired!</AlertTitle>
            <AlertDescription>Please upgrade your plan to continue using the app.</AlertDescription>
            <Link href={STRIPE_BUTTON_LINK}>
              <Button colorScheme="blue" size="sm" ml={2} leftIcon={<GrUpgrade />}>
                Upgrade Plan
              </Button>
            </Link>
          </Alert>
        );
    }
  } else if (
    userSelfQuery?.isSuccess &&
    [userSelfQuery.data?.plan_name, userSelfQuery.data?.plan_tier].includes(PLAN_NAME_FREE)
  ) {
    const { plan_expiry } = userSelfQuery.data;
    return (
      <Alert status="warning" borderRadius="lg" marginBottom={4}>
        <AlertIcon />
        <AlertTitle>
          Your subscription is about to expire at {getDateTime(plan_expiry.toString(), '-')}!
        </AlertTitle>
        <AlertDescription>Please upgrade your plan to continue using the app.</AlertDescription>
        <Link href={STRIPE_BUTTON_LINK}>
          <Button colorScheme="blue" size="sm" ml={2} leftIcon={<GrUpgrade />}>
            Upgrade Plan
          </Button>
        </Link>
      </Alert>
    );
  }

  return null;
};

export default SubcribeAlert;
