import {
  createApi,
  fetchBaseQuery,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { REACT_APP_BASE_URL } from 'utils/consts';
import { clearSession, getSessionType, getSessionid, getUserId } from 'utils/sessionStorage';

export const enum RtkTagTypes {
  'Clients' = 'Clients',
  'Client' = 'Client',
  'Projects' = 'Projects',
  'ProjectsSummary' = 'ProjectsSummary',
  'ProjectsTopics' = 'ProjectsTopics',
  'ProjectsOutline' = 'ProjectsOutline',
  'UsersSelf' = 'UsersSelf',
  'ProjectImage' = 'ProjectImage',
}

export const tagTypes = [
  RtkTagTypes.Clients,
  RtkTagTypes.Projects,
  RtkTagTypes.ProjectsSummary,
  RtkTagTypes.ProjectsTopics,
  RtkTagTypes.ProjectsOutline,
  RtkTagTypes.UsersSelf,
  RtkTagTypes.ProjectImage,
];

const prepareHeaders = (headers: Headers) => {
  headers.set('session-id', getSessionid());
  headers.set('session-type', getSessionType());
  headers.set('user-id', getUserId());
  return headers;
};

const customFetchBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  const baseQuery = fetchBaseQuery({
    baseUrl: REACT_APP_BASE_URL,
    prepareHeaders,
  });

  const timeout = 30000; // Set timeout to 30 seconds
  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), timeout);

  try {
    // Execute the baseQuery with a timeout signal
    const result = await baseQuery(
      typeof args === 'string'
        ? { url: args, signal: controller.signal }
        : { ...args, signal: controller.signal },
      api,
      extraOptions,
    );

    // Handle errors such as 401, 402, 404 manually
    if (result.error) {
      if (result.error.status === 401) {
        clearSession();
        window.location.href = '/'; // Redirect to login page
      } else {
        // Return any other type of error by baseQuery
        return { error: result.error };
      }
    }

    return result;
  } catch (error) {
    const typedError = error as Error;
    if (typedError.name === 'AbortError') {
      console.error('Request timed out');
      // Return a FetchBaseQueryError that matches the expected type signature
      const fetchError: FetchBaseQueryError = {
        status: 'CUSTOM_ERROR', // Use 'CUSTOM_ERROR' since we're providing a custom message
        error: 'Request timed out', // Provide the required 'error' field
        data: undefined, // The data can be undefined for this error
      };
      return {
        error: fetchError,
      };
    }
    throw error; // Re-throw the error if it wasn't a timeout
  } finally {
    clearTimeout(timeoutId);
  }
};

export const baseApi = createApi({
  reducerPath: 'api',
  baseQuery: customFetchBaseQuery, // Using the custom fetch base query with timeout and error handling
  tagTypes,
  endpoints: () => ({}), // Define endpoints in other services/api files
});
