import { Divider, Heading, Icon, Image, VStack } from '@chakra-ui/react';
import Button from 'components/common/Button';
import React from 'react';
import { FaHome } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { ClientsList } from './sidebar/ClientsList';
import { UserInfo } from './sidebar/UserInfo';
import { TermsAndConditions } from './sidebar/TermsAndConditions';

const Sidebar = () => {
  const navigate = useNavigate();

  return (
    <VStack p={8} spacing={4} align="stretch" bg="purple.50" minH="100vh" width="300px">
      {/* Logo */}
      <Heading size="md" mb={2}>
        <Image src="/svgs/yai-logo.svg" alt="YAi" />
      </Heading>

      {/* Home Button with Highlight */}
      <VStack align="start" justifyContent="left">
        <Button
          variant="ghost"
          onClick={() => navigate('/dashboard')}
          bg="purple.100"
          color="purple.800"
          w="full"
          _hover={{ bg: 'purple.200' }}
          leftIcon={<Icon as={FaHome} boxSize="1.2em" color="purple.700" />}
        >
          Home
        </Button>

        <Divider my={4} />

        {/* Projects List */}
        <ClientsList />
      </VStack>

      <Divider my={4} />

      {/* User Information and Logout */}
      <UserInfo />

      {/* Footer */}
      <TermsAndConditions />
    </VStack>
  );
};

export default Sidebar;
