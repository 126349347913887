import Avatar from 'components/common/Avatar';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'components/common/Button';

import { Box, Flex, Icon, Text, VStack, Tag, TagLabel, Tooltip } from '@chakra-ui/react';
import { FiLogOut } from 'react-icons/fi';
import { useGetUserSelfQuery } from 'services/api/user';
import { clearSession, getUserSession } from 'utils/sessionStorage';

export const UserInfo: React.FC = () => {
  const userInfo = getUserSession();
  const { data: userSelfData, isSuccess: isUserLoaded } = useGetUserSelfQuery();
  const navigate = useNavigate();

  const handleLogout = () => {
    clearSession();
    navigate('/', { replace: true });
  };

  return (
    <VStack
      align="stretch"
      spacing={4}
      p={4}
      borderRadius="md"
      bg="white"
      boxShadow="md"
      width="100%"
    >
      {/* User Information Section */}
      <Flex align="center">
        <Avatar
          name={userInfo?.given_name}
          logo={userInfo?.picture}
          boxSize="48px"
          borderRadius="full"
        />
        <Box>
          <Text fontWeight="bold" fontSize="lg" color="purple.800">
            {userInfo?.given_name || 'User'}
          </Text>

          {/* Email as a Chip */}
          {userInfo?.email && (
            <Tooltip label={userInfo.email} aria-label="User email" placement="top">
              <Tag
                size="sm"
                colorScheme="gray"
                mt={1}
                maxW="150px"
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                <TagLabel>{userInfo.email}</TagLabel>
              </Tag>
            </Tooltip>
          )}

          {/* Subscription Type as a Chip */}
          {isUserLoaded && userSelfData?.plan_name && (
            <Tag size="sm" colorScheme="purple" mt={2}>
              <TagLabel>Plan: {userSelfData.plan_name}</TagLabel>
            </Tag>
          )}
          {isUserLoaded && userSelfData?.plan_tier && (
            <Tag size="sm" colorScheme="purple" mt={2}>
              <TagLabel>Plan: {userSelfData.plan_tier}</TagLabel>
            </Tag>
          )}
        </Box>
      </Flex>

      {/* Logout Button */}
      <Button
        variant="outline"
        colorScheme="gray"
        leftIcon={<Icon as={FiLogOut} />}
        onClick={handleLogout}
        width="100%" // Full width for the button
        color="gray.600"
        fontSize="sm" // Smaller font size for better fit
        _hover={{ bg: 'gray.100' }}
        borderColor="gray.300"
      >
        Logout
      </Button>
    </VStack>
  );
};
