export const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL ?? 'https://staging.yai.digital/api';
export const REACT_APP_CLIENT_ID = process.env.REACT_APP_CLIENT_ID ?? '1057431999214-viann1jpbo44ednvh0amuc36v2tajpvs.apps.googleusercontent.com';
export const REACT_APP_SAFE_MODE = process.env.REACT_APP_SAFE_MODE ?? false;

// Using some default values for the Datadog configuration
// detaisl taken from Andreis Test account
// To be udpated with values added in the .env file
export const DATADOG_APPLICATION_ID = process.env.REACT_APP_DATADOG_APPLICATION_ID ?? '34b24106-627c-4acf-a703-1498cc13c75d';
export const DATADOG_CLIENT_TOKEN = process.env.REACT_APP_DATADOG_CLIENT_TOKEN ?? 'pub10087ee9843f30dbc7caf88bcbf773ff';
export const DATADOG_SITE = process.env.REACT_APP_DATADOG_SITE ?? 'datadoghq.eu';
export const DNS_PREFIX = process.env.REACT_APP_DNS_PREFIX ?? 'staging';

export const STRIPE_BUTTON_LINK = process.env.REACT_APP_STRIPE_BUTTON_LINK;

export const PLAN_NAME_FREE = 'free';
export const PLAN_NAME_PREIMUM = 'premium';
