import React, { useEffect } from 'react';
import { Divider, List, ListItem, VStack } from '@chakra-ui/react';

export const TermsAndConditions = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = `(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);`;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <VStack align="center" spacing={2} mt="auto">
      <Divider />

      <List>
        <ListItem>
          <a
            href="https://www.iubenda.com/privacy-policy/76710123"
            className="iubenda-white iubenda-noiframe iubenda-embed"
            title="Privacy Policy"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
        </ListItem>
        <ListItem>
          <a
            href="https://www.iubenda.com/privacy-policy/76710123/cookie-policy"
            className="iubenda-white iubenda-noiframe iubenda-embed"
            title="Cookie Policy"
            target="_blank"
            rel="noreferrer"
          >
            Cookie Policy
          </a>
        </ListItem>
        <ListItem>
          <a
            href="https://www.iubenda.com/terms-and-conditions/76710123"
            className="iubenda-white iubenda-noiframe iubenda-embed"
            title="Terms and Conditions"
            target="_blank"
            rel="noreferrer"
          >
            Terms and Conditions
          </a>
        </ListItem>
      </List>
    </VStack>
  );
};
